import firebase from 'firebase/app';
const firebaseConfig = {
    apiKey: "AIzaSyC6k6n1pY43GKp6dRSY0B7V7v0uAQYg-yY",
    authDomain: "sb-crime-report.firebaseapp.com",
    databaseURL: "https://sb-crime-report.firebaseio.com",
    projectId: "sb-crime-report",
    storageBucket: "sb-crime-report.appspot.com",
    messagingSenderId: "864430318202",
    appId: "1:864430318202:web:24202870ada27952e5d1c3",
    measurementId: "***************************************"
};
firebase.initializeApp(firebaseConfig);
console.log("firebase initialize")

export default firebase;